import { DateTime } from "luxon";
import { findLastIndex } from "lodash";
const DURATION_PERIOD = [
  { days: 0, period: 0 },
  { days: 8, period: 60 * 60 },
  { days: 14, period: 60 * 60 * 6 }
];

export function dateRangeToPeriod(startDate: DateTime, endDate: DateTime): number {
  const days = endDate.diff(startDate, "days").days;
  const index = findLastIndex(DURATION_PERIOD, g => days >= g.days);
  return DURATION_PERIOD[index].period;
}
